@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

$blue: #3c5091;
$blue-dark: #364268;
$bg: #e9eef5;
$bg-dark: #cbced1;
$header-height: 60px;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body,
html {
  height: 100vh;
  font-size: 18px;
  background-color: $bg;
}

// general element styles
.input {
  padding: 8px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  font-size: 1rem;
}

.button {
  border: 0px;
  padding: 16px 12px;
  background-color: $blue;
  color: #fff;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: $blue-dark;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.file-attach {
  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 6px;
    }
  }

  &__label {
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.tree-item {

  &__header {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $bg-dark;
    }

    img.item-arrow {
      max-width: 20px;
      margin-right: 12px;
      transition: transform 0.3s ease-in-out;
      opacity: 0.5;

      &.opened {
        transform: rotate(90deg);
      }
    }

    img.item-icon {
      margin-right: 6px;
    }

    label {
      font-weight: bold;
      pointer-events: none;

      &.leaf {
        font-weight: normal;
      }
    }
  }

  &__children {
    border-left: 1px solid $blue;
    padding-left: 20px;
  }
}

.loader-mask {
  position: fixed;
  background-color: #0004;
  z-index: 100;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: grid;
  place-items: center;

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #ffffff;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}

.app {
  display: grid;
  grid-template-rows: $header-height 1fr;
}

.header {
  background-color: $blue;
  padding: 0px 25px;
  display: flex;
  align-items: center;

  &__logo {
    max-width: 35px;
    margin-right: 10px;
    cursor: pointer;
  }

  &__title {
    color: #fff;
    font-size: 1rem;
    text-shadow: 0px 2px 5px #0002;
  }

  .network-state {
    display: inline-block;
    font-size: 0.8rem;
    color: #fff;
    border-radius: 8px;
    margin-left: 6px;
    padding: 4px 8px;

    &.offline {
      background-color: red;
    }

    &.online {
      background-color: green;
    }
  }

  &__menu {
    margin-left: auto;

    button.szh-menu-button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      img {
        width: 30px;
      }
    }

    ul.szh-menu {
      background-color: $blue;
      list-style-type: none;
      border-radius: 6px;
      overflow: hidden;
      & > li {
        padding: 18px 36px;
        color: #fff;
        cursor: pointer;

        &:hover {
          background-color: $blue-dark;
        }
      }
    }
  }
}

.login {
  display: grid;
  place-items: center;
  height: calc(100vh - $header-height);

  &__form {
    max-width: 500px;
    margin: 0px 20px;
    background-color: #fff;
    padding: 25px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #0002;
  }

  &__title {
    font-size: 1.3rem;
    text-align: center;
    padding-bottom: 35px;
  }

  &__input {
    width: 100%;
    margin-bottom: 12px;
  }

  &__button {
    width: 100%;
  }
}

.app-screen {
  height: calc(100vh - $header-height);
  overflow-y: scroll;

  & > .tree-item {
    padding-left: 0px;
  }
}
.db-selector {
  height: calc(100vh - $header-height);
  overflow-y: scroll;

  a {
    text-decoration: none;
    color: #000;
  }

  &__item {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;

    &:hover {
      background-color: $bg-dark;

      label {
        transform: translateX(20px);
      }
    }

    img {
      width: 30px;
    }

    label {
      font-weight: bold;
      font-size: 1.2rem;
      flex: 1;
      text-align: center;
      transition: transform 0.3s ease-in;
    }
  }
}

.add-meter {
  padding: 20px;
  height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  &__input {
    width: 100%;
    margin-bottom: 2rem;
  }

  &__label {
    margin-bottom: 8px;
  }

  &__submit {
    width: 100%;
    margin-top: auto;
    cursor: pointer;
  }
}

.sync-meters-screen {
  height: calc(100vh - $header-height);
  overflow-y: scroll;
  padding: 20px;

  .empty-text {
    background-color: $bg-dark;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
  }

  .sync-meter-item {
    padding: 16px;
    margin-bottom: 20px;
    border-left: 4px solid $blue;
    background-color: darken($color: $bg, $amount: 5);

    &__content {
      flex: 1;

      & > div {
        margin-bottom: 8px;
      }

      span {
        display: inline-block;
        margin-left: 6px;
      }
    }

    &__buttons {
      display: flex;
      gap: 10px;
      padding-top: 20px;
      
      .button {
        flex: 1;

        &.btn-delete {
          background-color: rgb(157, 43, 43);

          &:hover {
            background-color: rgb(106, 26, 26);
          }
        }
      }
    }
  }
}
